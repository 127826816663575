import { WithStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const cancelModalStyles = (
  theme: Theme
): {
  root: any;
} => ({
  root: {
    '& .MuiPaper-root': {
      padding: 24,
      marginTop: '20%',
    },
    '& .MuiDialog-scrollPaper': {
      height: 'inherit',
    },
    '& .MuiDialogTitle-root': {
      padding: '0',

    },
    '& .MuiDialogActions-root': {
      padding: 0,
      flexDirection: 'column',
      '& .ButtonGroup': {
        display: 'flex',
        width: '100%',
        '& button': {
          margin: 8,
          flexGrow: 1
        }
      }
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      background: 'none',
      '& .MuiPaper-root': {
        minWidth: '80%',
      },
      '& .MuiDialog-scrollPaper': {
        //maxHeight: 'calc(100% - 350px)',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& .MuiPaper-root': {
        padding: 16,
        //height: '100%',
        maxHeight: 350,
      },
      '& .MuiDialogActions-root': {
        '& .ButtonGroup': {
          flexDirection: 'column',
          '& button': {
            margin: '8px 0 0 0 !important',
          }
        }
      }      
    },
  },
});

export type StyleProps = WithStyles<typeof cancelModalStyles>;

export default cancelModalStyles;