import React from 'react';
import Redux from 'redux';
import { connect } from 'react-redux';
import { Card, CardContent, CardActions, CardHeader, withStyles } from '@material-ui/core';
import { Body, Subheadline, Link, Button } from '@clublabs/ace-component-library';
import { Notification, NotificationType, ConfigResponseApp, IconFlags, ProductType } from 'shared';
import notificationCardStyles, { StyleProps } from './NotificationCardStyles';
import { iconHelper } from '../Common/IconHelper';
import { NotificationHandler, HandleViewClickArguments } from '../Common/NotificationHandlers';
import { RootState } from '../../redux/store/rootReducer';
import { utility } from 'utils';

export interface RequiredProps {
  cardData: Notification;
  config: ConfigResponseApp;
}

const mapStateToProps = (rootState: RootState) => {
  return {
    rootState: rootState,
    userState: rootState.userState,
    modalState: rootState.modalState,
    analytics: rootState.analyticsState,
    errors: rootState.errorState,
    tabState: rootState.tabContainerState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return {
    urlClick: (args) => NotificationHandler.handleUrlClick(dispatch, args),
    cancelClick: (args) => NotificationHandler.handleCancelClickV2(dispatch, args),
    payClick: (args) => NotificationHandler.handlePayClick(dispatch, args),
    viewBillClick: (args, config) => NotificationHandler.handleViewClick(dispatch, args, config),
  };
};

export type BillingCardProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  StyleProps;

const BillingCard: React.FunctionComponent<BillingCardProps> = (props) => {
  const productId =
    props.cardData.productType === ProductType.MEMBERSHIP
      ? utility.formatMembership(props.cardData.productId)
      : props.cardData.productId;
  const taggingModel = props.analytics && props.analytics.model;
  const viewBillClickProps: HandleViewClickArguments = {
    notification: props.cardData,
    taggingModel: taggingModel!,
    userState: props.userState!,
    tabState: props.tabState!,
  };
  return (
    <Card data-quid="notifications" className={props.classes.notificationCard} key={props.cardData.productId}>
      <CardHeader
        className={props.classes.cardHeader}
        title={props.cardData.title}
        subheader={<span data-di-mask>{`${props.cardData.productIdLabel} #: ${productId}`}</span>}
      ></CardHeader>
      <CardContent className={props.classes.cardContent}>
        {props.cardData.showPaymentDetails && props.cardData.type !== NotificationType.BILLING_REINSTATE && (
          <Body id="pay-plan">
            Pay plan: {props.cardData.payPlan} | Auto Pay: {props.cardData.isAutoPay ? 'Yes' : 'No'}
          </Body>
        )}
        {props.cardData.showPaymentDetails && props.cardData.paymentType !== '' && (
          <Body id="pay-account">
            Payment account: {props.cardData.paymentType}
            {props.cardData.lastFourDigits !== '' && (
              <span
                id={`${props.cardData.productId}-lastFourDigits`}
                className={props.cardData.highlightCreditCardNum ? props.classes.highlightedText : ''}
                data-di-mask
              >
                ****{props.cardData.lastFourDigits}
              </span>
            )}
          </Body>
        )}
        {(props.cardData.date || props.cardData.type === NotificationType.PAYMENT_IN_TRANSIT) && (
          <div id="pay-status" className={props.classes.payStatus}>
            {props.cardData.showRecurringIconFlag && (
              <Body id="recurring-icon" className={props.classes.payStatusIcon}>
                {' '}
                {iconHelper(IconFlags.IS_RECURRING)}
              </Body>
            )}
            {(props.cardData.type === NotificationType.BILLING_DUE ||
              props.cardData.type === NotificationType.BILLING_REINSTATE) && (
              <Body id="notification-status">
                Due:{' '}
                <span
                  id="due-date"
                  className={props.cardData.isPastDue ? props.classes.pastDue : props.classes.doNothing}
                >
                  {props.cardData.date}
                </span>
              </Body>
            )}
            {props.cardData.type === NotificationType.BILLING_PAID && (
              <Body id="notification-status">Paid: {props.cardData.date}</Body>
            )}
            {props.cardData.type === NotificationType.PAYMENT_SCHEDULED && (
              <>
                <Body id="notification-status">Scheduled for: {props.cardData.date}</Body>
              </>
            )}
            {props.cardData.type === NotificationType.PAYMENT_IN_TRANSIT && (
              <Body id="notification-status">
                {props.config && props.config.Messages.NotificationMessages.PaymentInTransit}
              </Body>
            )}
          </div>
        )}
        {props.cardData.currentBalance &&
          props.cardData.type !== NotificationType.BILLING_REINSTATE &&
          props.cardData.currentBalance !== props.cardData.amount && (
            <div className={props.classes.customFlex}>
              <Body id="pay-currentbalance" className={props.classes.amountText}>
                Current balance:
              </Body>
              <Subheadline id="pay-currentbalance" className={props.classes.amountText}>
                ${props.cardData.currentBalance}
              </Subheadline>
            </div>
          )}
        <div className={props.classes.customFlex}>
          {(props.cardData.type === NotificationType.BILLING_DUE ||
            props.cardData.type === NotificationType.BILLING_REINSTATE) && (
            <Body id="pay-amount" className={props.classes.amountText}>
              Amount due:
            </Body>
          )}
          {props.cardData.type !== NotificationType.BILLING_DUE &&
            props.cardData.type !== NotificationType.BILLING_REINSTATE && (
              <Body id="pay-amount" className={props.classes.amountText}>
                Amount:
              </Body>
            )}
          <Subheadline id="pay-amount" className={props.classes.amountText}>
            ${props.cardData.amount}
          </Subheadline>
        </div>
        {props.cardData.message && (
          <div id="notification-alert" className={props.classes.notificationAlert}>
            <div id="notification-alert-icon" className={props.classes.notificationAlertIcon}>
              {iconHelper(props.cardData.iconFlag)}
            </div>
            <div className={props.classes.notificationAlertMessage}>
              <div data-quid="notification-alert-message">{props.cardData.message}</div>
              {props.cardData.showUpdatePaymentAccountsLink && (
                <div id="notification-alert-link">
                  <Link
                    id="notification-link"
                    target="_self"
                    component="a"
                    onClick={(e) => {
                      props.urlClick &&
                        props.urlClick({
                          url: props.cardData.updatePaymentAccountsUrl,
                          userState: props.userState!,
                          config: props.config,
                        });
                      e.preventDefault();
                    }}
                  >
                    Update payment accounts.
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
        {props.cardData.hint && (
          <div id="notification-alert" className={props.classes.notificationAlert}>
            <div className={props.classes.notificationAlertIcon}>{iconHelper(IconFlags.SELL_BLACK)}</div>
            <div className={props.classes.notificationAlertMessage}>
              <div data-quid="notification-alert-message">{props.cardData.hint}</div>
            </div>
          </div>
        )}
        <div className={props.classes.customFlex}>
          <Body id="pay-wait-notification" className={props.classes.amountText}>
            <span className={props.classes.payWaitNotification}>
              Please allow up to 3 business days for your payment to be reflected in your account.
            </span>
          </Body>
        </div>
      </CardContent>
      <CardActions className={props.classes.cardActions}>
        {props.cardData.paymentEnabled && (
          <Button
            id="pay-bill"
            className={props.classes.cardAction}
            onClick={() =>
              props.payClick &&
              props.payClick({
                notification: props.cardData,
                config: props.config,
                rootState: props.rootState,
                modalState: props.modalState,
              })
            }
          >
            {props.cardData.showPayNowLabel ? 'Pay Now' : 'Pay bill'}
          </Button>
        )}
        {props.cardData.cancellationEnabled && (
          <Button
            id="cancel-payment"
            className={props.classes.cardAction}
            color="secondary"
            onClick={() =>
              props.cancelClick &&
              props.cancelClick({ notification: props.cardData, userState: props.userState!, config: props.config })
            }
          >
            Cancel payment
          </Button>
        )}
        {props.cardData.productType !== ProductType.MEMBERSHIP && props.cardData.viewBillEnabled && (
          <Button
            id="view-bill"
            onClick={() => props.viewBillClick && props.viewBillClick(viewBillClickProps, props.config)}
            color="secondary"
          >
            View bill
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(notificationCardStyles, { withTheme: true })(BillingCard));
